/* /////////////////////////////////////////// section 2 //////////////////////////////////////////// */


.Events-card {
    /* border: 1px solid red; */
    border-radius: 5px;
    box-shadow: 0 0 5px grey;
    margin: 10px 10px 30px 10px;
    /* background: black; */
    padding: 5px;
}

.Events-Main-Heading {
    margin-bottom: 20px;
}

.Event-card-date-con {
    text-align: start;
    padding: 10px 20px 0px 20px;
    display: flex;
    align-items: center;
}

.Event-card-date-con svg {
    color: white;
}

.Event-card-date-con h3 {
    margin: 0px 0px 0px 10px;   
    font-family: var(---headerFont);
    font-size: 15px;
    color: white;
}

.Events-Section02 a {
    text-decoration: none;
}

.Events-card:hover {
    transform: translateY(-5px);
    transition: transform .25s, -webkit-transform .25s;
}

.Events-card-Image .ant-image{
    /* border: 1px solid blue; */
    height: 60vh;
}

.Events-card-Image .ant-image img {
    height: 100%;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}


.Events-card-MainD {
    margin: 5px;
}

.Events-card-MainD h4 {
    font-size: 20px;
    text-align: left;
    color: white;
    font-family: 'Amiri', serif;
    padding: 10px 10px;
}

.Events-card-MainD p {
    font-size: 15px;
    text-align: left;
    color: maroon;
    font-family: 'Amiri', serif;
}

.moreButton {
    padding: 15px;
    text-align: left;
}

.moreButton button {
    border: none;
    background-color: maroon;
    color: var(---TheamGoldColor);
    border-radius: 5px;
    padding: 5px;
}

@media(max-width:450px) {

    .Events-Main-Heading {
        margin: 20px 0px 0px 0px;
    }

    .Events-Main-Heading h1 {
        font-size: 37px;
    }
}