.Placement-section-1 {
    /* border: 1px solid red; */
    padding: 5vh 0px;
}

.Placement-Section-Details-con {
    /* border: 1px solid blue; */
}

.Placement-Section-Details-Info-con {}

.Placement-Section-Details-Info-con {
    /* border: 1px solid green; */
}

.Placement-Section-Details-Info-con h2 {
    display: inline-block;
    position: relative;
    font-size: 20px;
    font-family: var(---headerFont);
    margin: 0px;
    color: var(---TheamMColor);
}

.headstyle::after {
    position: absolute;
    content: "";
    top: 50%;
    left: -60px;
    height: 3px;
    width: 50px;
    background-color: var(---TheamGoldColor);
    border-radius: 30px;
}

.headstyle::before {
    position: absolute;
    content: "";
    top: 50%;
    right: -60px;
    height: 3px;
    width: 50px;
    background-color: var(---TheamGoldColor);
    border-radius: 30px;
}

.Placement-Section-1-Details-Info-con {
    /* border: 1px solid blue; */
    padding: 40px 0px;
}

.Placement-Section-1-Info-Para {}

.Placement-Section-1-Info-Para p {
    text-align: left;
    font-family: var(---headerFont);
}


.placement-Section-clients-points-con {
    /* border: 1px solid red; */
    margin: 30px 0px;
}

.placement-Section-clients-points-con a {
    text-decoration: none;
    color: black;
    font-size: 16px;
}

.Placement-Clients-Points {
    text-align: left;
    margin-bottom: 10px;
}

.placement-points-icons {
    text-align: left;
}

.placement-section-3-con {
    /* border: 1px solid red; */
}

.Placement-section-image-con-main {
    /* border: 1px solid red; */
    height: auto;
}

.Placement-section-image-con-main img {
    height: 100%;
    width: 100%;
}



/* section images con  */


.placement-section-images-swiper-con {}

.placement-section-images-swiper {}

.placement-section-images-swiper-last-section {
    height: 45vh;
    width: 100%;
    margin-bottom: 40px;
}

.placement-section-images-swiper-last-section img {
    height: 100%;
    width: 100%;
}


.main-section-3-para-con {
    text-align: justify;
    font-family: var(---headerFont);
}

@media(max-width:768px) {
    .Placement-Section-Details-con {
        padding: 0px 30px;
    }

    .placement-section-images-swiper-last-section {
        height: auto;
    }
}

@media(max-width:576px) {
    .FAQ-Banner-text h4 {
        display: none;
    }
}