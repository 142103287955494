@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

/* ///////////////////////// section 1 //////////////////////////// */

.Alumni-Section {
  /* border: 1px solid black; */
}

.Alumni-banner-Image {
  height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url("../Images/SlidePics/Slide\ Pics42.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;

}

.Alumni-Banner-text h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: 'Anton', sans-serif;
  letter-spacing: 15px;
  color: white;
}

.Alumni-Banner-text h4 {
  font-size: 25px;
  font-weight: bold;
  font-family: 'Tiro Telugu', serif;
  color: white;
}

/* ///////////////////////// section 2 //////////////////////////// */

.Alumni-Notable {
  margin-top: 5vh;
}

.Alumni-Notable-title {
  display: inline-block;
  background: #dee2e6;
  border-radius: 35px;
  margin-bottom: 15px;
}

.Alumni-Notable-title h4 {
  padding: 5px 15px 0px 15px;
}

.d-flex {
  display: flex;
}

.flip-card {
  background-color: transparent;
  width: 500px;
  height: 350px;
  perspective: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform .98s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-face,
.flip-card-behind {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-face img {
  height: 100% !important;
  width: 100% !important;
  border-radius: 5px;
}

.flip-card-face {
  background-color: #bbb;
  color: black;
  width: 500px;
  height: 350px;
  box-shadow: 0 0 5px #808080;
  border-radius: 5px;
}

/* Style the back side */
.flip-card-behind {
  color: white;
  transform: rotateY(180deg);
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px #808080;
  border-radius: 5px;
}

.la {
  background-image: linear-gradient(to right top, #ff9bf7, #ff90c7, #ff9c89, #ffbe4d, #f8e526);
}

.hawai {
  background-image: linear-gradient(to right bottom, #9bf2ff, #79e4ff, #7cd0ff, #a6b6ff, #d994ff);
}



/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.Alumni-Notable-Section2 {
  padding-top: 10vh;
  padding-bottom: 10vh;
  background-image: url("../Images/pricing-table-bg.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: -2;
}

.Alumini-S2-Heading {
  position: relative;

}

.Alumini-S2-Heading h4 {
  color: white;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 30px;
}

/* .Alumini-S2-Heading h5{
  position: absolute;
  top: -30px;
  left: -11px;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 63px;
  color: lightgrey;
  z-index: -14;
} */

.Alumini-S2-Description {}

.Alumini-S2-Description h3 {
  font-size: 40px;
  color: white;
  font-family: 'Permanent Marker', cursive;
}

/* ///////////////////////// section 3 //////////////////////////// */



.Alumni-Swiper {
  margin-top: 5vh;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}


@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
} */

.AlumniSection3 {
  margin-top: 10vh;
  margin-bottom: 10vh;
}


/* ///////////////////////////////////// */
.my-card {
  height: auto;
  /* box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
  position: relative;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 16px 0px 16px 20px;
}

.img-place {
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

#card-img {
  width: 230px;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* background-image: url("../Images/category-mens.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
  transition: 0.5s;
}


#card-img img {
  width: 265px;
  height: 40vh;
  object-fit: cover;
  object-position: top;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: all .5s ease-in-out;
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content :hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.card-2-space {
  padding: 0 40px 20px 40px;
}

.card-text {
  padding: 5%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #1a1a1a;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text h5 {
  color: white;
  font-family: var(---titleFont);
}

.AlumniSection3 a {
  text-decoration: none;
}

.card-btn {
  width: 100%;
  margin-top: 10px;
}

.my-btn {
  width: 100%;
  background-color: #000000;
  border: 0px;
  padding: 8px;
  color: #ffffff;
  transition: 0.3s;
}

.my-btn:hover {
  background-color: #666699;
}


.btn {
  background-color: #0d0d0d;
  color: #ffffff;
  padding: 10px;
  border: 1px solid #1a1a1a;
  border-radius: 8px;
  transition: 0.3s;
}

.btn:hover {
  background-color: #bfbfbf;
  color: #0d0d0d;
  border: 1px solid #808080;
  font-weight: 300px;
}

/* alumni Name */

.AlumniTitle {
  position: relative;
  margin-bottom: 10vh;
  margin-top: 10vh;
}

.AlumniTitle h5 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.AlumniTitle h4 {
  position: absolute;
  font-weight: bold;
  top: -51px;
  left: 66px;
  right: 0;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;
}


@media (max-width: 770px) {
  #card-img {
    height: 250px;
    width: 250px;
  }
}


@media(max-width:768px) {
  .Alumni-banner-Image {
    height: 50vh;
    background-size: cover;
    background-position: center;
  }

  #card-img img {
    width: 265px;
    height: 46vh;
  }
}

@media(max-width:576px){
  .Alumni-banner-Image {
    height: 30vh !important;
}
}