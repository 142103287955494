.whyabss-Section {
    /* border: 1px solid black; */
}

.whyabss-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../Images/SlidePics/Slide\ Pics18.JPG");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.whyabss-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.whyabss-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

/* /////////////////////////////////////////////////////////// */

.whyabss-section-2 {
    padding: 5vh 0px;
}

.whyabss-section-main-con {
    /* border: 1px solid blue; */
}

.whyabss-section-heading-con {
    /* border: 1px solid red; */
}

.whyabss-section-heading-con h3 {
    font-family: var(---headerFont);
    text-transform: capitalize;
    font-size: 30px;
    position: relative;
    display: inline-block;
}

.whyabss-section-details-con {
    /* border: 1px solid red; */
    padding: 10vh;
}

.whyabss-section-details {
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
}

.whyabss-section-image-con {
    /* border: 1px solid black; */
    height: 40px;
    width: 40px;
    min-width: max-content;
}

.whyabss-section-image-con img {
    height: 100%;
    width: 100%;
}

.whyabss-section-details p {
    margin: 10px 0px 0px 10px;
    text-align: left;
    font-family: var(---headerFont);
}

@media(max-width:768px) {
    .whyabss-section-details-con {
        padding: 0px;
    }

    .whyabss-banner-Image {
        height: 50vh;
    }
}

@media(max-width:576px){
    .whyabss-section-heading-con h3 {
        font-size: 22px;
    }
    .whyabss-banner-Image {
        height: 30vh;
    }
}