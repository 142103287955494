/* ///////////////////////// section 1 //////////////////////////// */

.Images-Section {
  /* border: 1px solid black; */
}

.Images-banner-Image {
  height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url("../Images/Group.webp");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.Images-Banner-text h1 {
  font-size: 45px;
  font-weight: bold;
  font-family: 'Anton', sans-serif;
  letter-spacing: 15px;
  color: white;
}

.Images-Banner-text h4 {
  font-size: 25px;
  font-weight: bold;
  font-family: 'Tiro Telugu', serif;
  color: white;
}

/* /////////////////////////// Section 2 /////////////////////////////////////////// */

.Image-Gallery {
  margin-top: 5vh;
}

.Image-Gallery-title {
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;

}

.Image-Gallery-title h4 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.Image-Gallery-title h3 {
  position: absolute;
  font-weight: bold;
  top: -51px;
  left: 81px;
  right: 0;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;
}

.ImageG-title {
  display: inline-block;
  background: #dee2e6;
  border-radius: 35px;
  margin-bottom: 15px;
}

.ImageG-title:hover {
  cursor: pointer;
}

.ImageG-title h4 {
  padding: 5px 15px 0px 15px;
}

.G-Images {
  margin: 10px;
  height: 40vh;
  box-shadow: 0 0 5px #808080;
  border-radius: 5px;
}

.G-Images img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-position: center;
  object-fit: cover;
}


@media(max-width:425px) {
  .Image-Gallery-title h3 {
    font-size: 55px;
    top: -27px;
    left: 0px;
  }
}





/* /////////////////////////////////////////////// demo gallery ////////////////////////////////////////////////// */

.Demogallery {}

h1 {
  font-family: Satisfy;
  font-size: 50px;
  text-align: center;
  color: black;
  padding: 1%;
}

#gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;

  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

@media (max-width:1200px) {
  #gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

@media (max-width:800px) {
  #gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;

  }

  .Images-banner-Image {
    height: 50vh;
    background-size: cover;
    /* transform: translate(10px,10px); */
    transition: calc(10px, 10px);
  }
}

@media (max-width:600px) {
  #gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

#gallery img,
#gallery video {
  width: 100%;
  height: auto;
  margin: 4% auto;
  box-shadow: 0px 0px 5px #000;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.modal-img,
.model-vid {
  width: 100%;
  height: auto;
}

.modal-body {
  padding: 0px;
}



/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6));
  display: flex;
  align-items: center;
}

.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay>span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0px;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0px;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}

@media(max-width:768px) {
  .G-Images {
    height: auto;
  }
}