html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.nav-link {
    margin: 0px;
    padding: 0px;
    display: grid;
}

.dropdown-toggle {
    background-color: transparent !important;
    border: none;
    margin: 0px;
    padding: 0px;
}

.dropdown {
    /* margin: 10px; */
}

.navbar-dark .navbar-nav .nav-link {
    text-align: left;
    color: #febf38;
}

.dropdown-toggle::after {
    display: none !important;
}

.navbar img {
    height: 80px;
    width: 100px;

}

.NavBar {
    background-color: var(---blackColor);
}

.NavBar-bg {
    background-color: black;
    top: 0;
    left: 0;
    right: 0;
}

.navbar-nav {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Social-media-icons {
    display: contents;
}

.nav-link a {
    text-decoration: none;
    color: #febf38;
}

.navbar-dark .navbar-nav .nav-link {
    text-align: left;
    margin: 0px;
}

.Social-media-icons {
    text-align: left;
}

@media(max-width:992px) {
    .Social-media-icons {
        display: none;
    }
}

.nav-link a:hover {
    color: rgb(0, 8, 255);
}

/* ////////////////////////////////////////// NavBar-icons ///////////////////////////////////////////////// */

.Social-media-icons svg {
    color: white;
    font-size: 20px;
}

.Social-media-icons a:hover svg {
    color: #febf38;
}

.Social-media-icons a {
    margin-left: 8px;
    margin-right: 8px;
}

.modal-title {
    font-family: var(---headerFont);
    font-size: 22px;
}

.navbar-main-con-input-div{
margin-bottom: 20px;
}

.navbar-main-con-input-div .form-label{
    font-family: var(---headerFont) !important;
}
.navbar-main-con-input-div .form-select{
    margin: 0px !important;

}


/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */


.header-material {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.logo {
    /* border: 1px solid red; */
}

.Email_Support {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
}

.Call_Support {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid saddlebrown; */
}

.Workinghr {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid aqua;*/
}

.Appointment {
    /* border: 1px solid darkgreen; */
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Appointment button {
    border-style: none;
    border-radius: 4px;
    background-color: #30d2b9;
    width: 175px;
    color: white;
}

.Appointment:hover button {
    color: rgb(0, 8, 255);
}



.header-material {
    width: 100%;
    height: 5rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}


.Email-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Email-text {
    font-size: 15px;

}

.Email-text span {
    color: rgb(84, 84, 84);
}

.Email-text h5 {
    font-size: 17px;
    color: rgb(117, 117, 117);
}

.Call-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Call-text {
    font-size: 15px;
}

.Call-text span {
    color: rgb(84, 84, 84);
}

.Call-text h5 {
    font-size: 17px;
    color: rgb(117, 117, 117);
}

.Time-logo {
    font-size: 30px;
    padding: 10px;
    margin-bottom: 15px;
    color: #30d2b9;
}

.Time-Text {
    font-size: 15px;
}

.Time-Text span {
    color: rgb(84, 84, 84);
}

.Time-Text h5 {
    color: rgb(117, 117, 117);
    font-size: 17px;
}

@media(max-width:992px) {
    .header {
        display: none;
    }
}


/* ///////////////////////// */

.dropdown-menu {
    background-color: var(---TheamMColor);
}

.dropdown-item:hover {
    background-color: var(---TheamGoldColor);
}

.nav-link a:hover {
    color: rgb(255, 255, 255);
}

.nav-item:hover .nav-link {
    color: white;
}


@media(max-width:995px) {
    .NavBar {
        display: block;
    }

    .navbar-nav {
        align-items: flex-start;
    }

    .dropdown {
        margin: 0px 0px 0px 0px;
    }

    .nav-link a {
        margin: 8px 0px;
    }

    .navbar-dark .navbar-nav .nav-link {
        margin: 8px 0px;
    }
}



/* ////////  */


.btn:focus {
    box-shadow: none;
}

.ModalBtn {
    /* backgroundColor: "#800000",
    position: "fixed",
    margin: "0",
    top: "40%",
    color: "#febf38",
    padding: "6px 14px",
    fontSize: "1.1rem",
    textDecoration: "none",
    fontWeight: "700",
    opacity: "1",
    right: "-70px",
    zIndex: "10000",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    transform: "rotate( 270deg)", */
    background-color: #800000 !important;
    position: fixed;
    margin: 0px;
    top: 40%;
    color: #febf38 !important;
    padding: 6px 14px !important;
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
    opacity: 1;
    right: -57px;
    z-index: 10000;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    transform: rotate(270deg);
}

@media(max-width:768px) {
    .ModalBtn {
        background-color: #800000 !important;
        position: fixed;
        margin: 0px;
        top: 40%;
        color: #febf38 !important;
        padding: 6px 14px !important;
        font-size: 12px;
        text-decoration: none;
        font-weight: 700;
        opacity: 1;
        right: -52px;
        z-index: 10000;
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
        transform: rotate(270deg);
    }
}