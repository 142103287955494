    /* ================================= Footer ============================ */

    .footer-basic {
        /* margin-top: 10vh; */
        background-color: var(---blackColor);
        color: #fff;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .footer-basic ul {
        padding: 0;
        list-style: none;
        text-align: center;
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 0;
    }

    /* .Footer{
        border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    } */

    .Footer-Main-Image {}

    .FooterLogo {
        height: 30vh;
        width: 30vh;
    }

    .FooterLogo img {
        height: 100%;
        width: 100%;
    }

    .footer-basic li {
        padding: 0 5px;
    }

    .footer-basic ul a {
        color: rgba(227, 227, 227, 0.604);
        text-decoration: none;
        opacity: 0.8;
        transition: 0.3s ease-in-out;
        padding: 3px 7px;
    }

    .footer-basic ul a:hover {
        opacity: 1;
        color: rgba(0, 0, 0, 0.597);
        background: rgb(255, 255, 255);
        transition: 0.5s ease-in-out;
        border-radius: 5px;
        box-shadow: 0 0 5px 5px var(---TheamGoldColor);
    }

    .footer-basic .social {
        text-align: center;
        padding-bottom: 25px;
    }

    .footer-basic .social>a {
        font-size: 24px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin: 0 8px;
        color: inherit;
        opacity: 0.75;
        transition: 0.5s ease-in-out;

    }

    .footer-basic .social>a:hover {
        opacity: 1;
        background: #fff;
        color: var(---primeColor);
        box-shadow: 0 0 5px 5px #fff;
        border: none;
    }

    .footer-basic .copyright {
        margin-top: 15px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-bottom: 0;
    }

    .copyright a {
        text-align: center;
        font-size: 14px;
        color: #fff;
        margin-bottom: 0;
    }

    .list-inline-item a {
        color: black;
    }


    /* //////////////////////////////// */

    .FooterImageCon {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .Footer-col-1 {
        width: 100%;
    }

    .Footer-col-2 {
        width: 100%;
    }

    .Footer-col-3 {
        width: 100%;
    }

    .Acting-logo-con {
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: center;
    }

    .Acting-logo-con span {
        height: 12vh;
        width: 20vh;
    }

    .Acting-logo-con span img {
        height: 100%;
        width: 100%;
        filter: invert(100%);
    }


    .Acting-logo-con h4 {
        font-size: 50px;
        margin: 8px 0px 15px 20px;
        font-family: 'Qwitcher Grypen', cursive;
        color: var(---whiteColor);
    }

    .Footer-Info p {
        text-align: left;
        padding: 10px;
        font-size: 13px;
    }

    .Footer_Social-Media {
        text-align: left;
        margin: 5px;
    }

    .Footer_Social-Media span a {
        color: white;

    }

    .Footer_Social-Media span svg {
        font-size: 30px;
        /* color: var(---TheamGoldColor); */
        margin: 5px;
        background: #212121;
        border-radius: 5px;
    }

    .Footer_Social-Media span:hover svg {
        color: var(---TheamGoldColor);
    }

    .FooterContact {
        margin: 25px 20px 20px 65px;
    }

    .FooterContact h4 {
        text-align: left;
        color: var(---whiteColor);
        font-family: 'Qwitcher Grypen', cursive;
        font-size: 40px;
        font-weight: bold;
    }

    .Contact-Details {
        margin: 20px 15px 0px 15px;

    }

    .Contact-Info {
        display: flex;
    }



    .icon {
        width: 20vh;
    }

    .icon svg {
        font-size: 30px;
        color: var(---whiteColor);
    }

    .FooterText {
        width: 130vh;
    }

    .FooterText h4 {
        font-size: 14px;
        text-align: left;
        padding-left: 20px;
    }

    .Footer_Social-Media span svg {
        font-size: 25px;
    }

    @media(max-width:1040px) {
        .FooterLogo {
            /* margin-left: 14vh; */
        }
    }


    @media(max-width:768px) {
        .FooterLogo {
            /* margin-left: 14vh; */
        }

        .Acting-logo-con {
            justify-content: center;
        }

        .Footer_Social-Media {
            text-align: center;
        }
    }
