/* ///////////////////////// section 1 //////////////////////////// */

.FAQ-Section {
    /* border: 1px solid black; */
}

.FAQ-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../Images/FAQImage.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.FAQ-Banner-text h1 {
    font-size: 50px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.FAQ-Banner-text h4 {
    font-size: 35px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}


/* ///////////////////////// section 2 //////////////////////////// */

.FAQName{
    position: relative;
    margin-bottom: 10vh;
    margin-top: 10vh;
}
.FAQName h5{
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
}
.FAQName h4{ 
    position: absolute;
    font-weight: bold;
    top: -51px;
    left: 66px;
    right: 0;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;
}
.FAQ-Section02{
    padding-bottom: 10vh;
}

@media(max-width:768px){
    .FAQ-banner-Image {
        height: 50vh;
    }
}

@media(max-width:576px){
    .FAQ-banner-Image {
        height: 30vh;
    }
}

@media(max-width:430px){
    .FAQ-Banner-text h1 {
        font-size: 30px;
    }
    .FAQ-Banner-text h4 {
        font-size: 25px;
    }
}