/* /////////////////////////////// Section 1 ////////////////////////////////// */


.EventInfoSection01 {
    /* border: 1px solid red; */
}

.EVSection-Image-con {
    position: relative;
}

.EVSection-Image {
    border: 1px solid black;
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6)), url("../Images/D_in_AImage01.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 60vh;
}

.EVSection-Heading {
    position: absolute;
    top: 15%;
    left: 0;
    bottom: 0;
    right: 0;
}

.EVSection-Heading h1 {
    color: #febf38c7;
}


/* /////////////////////////////// Section 2 ////////////////////////////////// */

.EventInfoSection02 {
    /* border: 1px solid red; */
}

.EVSection2Image-con {
    height: 80vh;
    margin: 40px;
}

.EVSection2Image-con img{
    height: 100%;
    width: 100%;
}

.EVSection2-Images-4{
    padding: 50px 10px 30px 10px;
}

.EVSection2ImageCon{

}

.EVSectionImage{
    margin: 10px;
    height:40vh;
    box-shadow: 0 0 5px gray;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.EVSectionImage img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: all .5s ease-in-out;
} 

.EVSectionImage:hover{
    transform: translateY(-5px);
    transition: transform .25s,-webkit-transform .25s;
}

.EVSectionImage img:hover{
    transform: scale(1.1);
}

.EVSection2-Information{
    margin: 20px;
}


@media(max-width:735px){
    .EVSection-Heading h1 { 
        font-size: 40px;
    }
}
@media(max-width:482px){
    .EVSection-Heading h1 { 
        font-size: 30px;
    }
}