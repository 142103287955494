.theatergroup-section-1-back-image{
    background: url("../../Images/Courses/theater\ group\ 5.jpg");
    background-size: cover;
    object-position: bottom;
}


.theater-group-section-2-details-text-con {
    /* border: 1px solid blue; */
}

.theater-group-section-2-details-head-con {
    display: flex;
    align-items: center;
}

.theater-group-section-2-details-head-con svg{
    color: #febf38;
}

.theater-group-section-2-details-text h3 {
    color: white;
    text-align: left;
    margin: 0px 0px 0px 10px;
    font-size: 18px;
}

.theater-group-section-2-details-text {
    text-align: left;
    margin-bottom: 20px;
}

.theater-group-section-2-details-text ul {
    margin: 0px 0px 0px 10px;
}

.theater-group-section-2-details-text ul li {
    color: white;
}