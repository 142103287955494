/* ///////////////////////// section 1 //////////////////////////// */

.CC-Section01 {
  overflow: hidden;
  background-color: #f3f0ed;
  padding-bottom: 10vh;
}

.CC-Title {
  margin: 20px;
}

.CC-Title h1 {
  color: black;
  font-size: 30px;
  font-family: var(---titleFont);
  font-weight: bold;
}

.CCTimeSchedule {
  padding: 30px;
  padding: 30px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  border-radius: 25px;
  background: white;
}

.CCFeeDetails {
  padding: 30px;
  height: 100%;
}

.CCFeeDetails-info h5 {
  text-align: left;
}


.CCTimeSchedule-title h3 {
  font-family: var(---titleFontS);
  font-weight: 600;
  color: black;
}

.FeeDetails-title h3 {
  font-family: var(---titleFontS);
  font-weight: 600;
  color: black;
}

.CCFeeDetails {
  padding: 30px;
  height: 100%;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  border-radius: 25px;
  background: white;
}

.CCTimeSchedule-info h5 {
  font-family: var(---paraFont);
  font-size: 15px;
  text-align: left;
}

.CCFeeDetails-info h5 {
  font-family: var(---paraFont);
  font-size: 15px;
}

/* ///////////////////////// section 2 //////////////////////////// */

.CC-Section02 {
  margin-top: 5vh;
  padding-bottom: 10vh;
}


.CC-Section02-Detail {
  margin: 20px;
  height: 100%;
  padding: 10px;
}

.CC-Section02-title h3 {
  color: black;
  font-size: 30px;
  font-family: var(---titleFont);
  font-weight: bold;
}

.CC-Section02-Detail:hover {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  border-radius: 25px;
}

.CC-Section02-days h4 {
  font-family: var(---titleFontS);
  font-weight: 600;
}

.CC-Section02-info {
  text-align: left;
}

.TimeSchedule-info h5 {
  text-align: left;
}

.CC-Section02-info p {
  margin: 5px;
  font-family: var(---paraFont);
}


/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */
/* 
.container {
    max-width: 1200px;
    margin-top: 50px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  } */

.title {
  font-size: 45px;
  color: #d9d9d9;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
    1px 1px 1px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-bottom: 20px;
}

.panels.panel-default {
  padding: 0 15px;
}

.relative {
  position: relative;
}

.panels {
  padding-bottom: 10px;
}

.panel-heading {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
  background-color: transparent;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 28px;
  border: 2px solid #fff;
  color: #000;
  border-radius: 4px;
  background: #e0e0e0;
  box-shadow: inset 20px 20px 60px #bebebe, inset -20px -20px 60px #ffffff;
}

.panel-title {
  position: relative;
  margin-bottom: 0;
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  letter-spacing: 2px;
}

.panel-heading:after {
  content: "\27AD";
  color: rgb(0, 0, 0);
  top: calc(50% - 19px);
  right: 20px;
  position: absolute;
  transition: all 0.3s ease;
  transform-origin: 10px 19px;
}

.panel-heading.change_icon::after {
  content: "\27AD";
  transform: rotate(90deg);
  transition: all 0.3s ease;
}

.panel-body__list li {
  list-style-type: none;
  margin-bottom: 4px;
  line-height: 21px;
  letter-spacing: 1.2px;
  position: relative;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  background: #fcfcfc;
  box-shadow: -8px 10px 30px #fff, 0 10px 30px #444444;
}

.panels .panel-collapse {
  position: relative;
  overflow-y: auto;
  max-height: 0;
  transition: max-height 0.3s ease, margin-bottom 0.2s ease-in;
}

.panel-collapse.collapse {
  max-height: 1000px;
}

.accordion-body p {
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    width: auto;
  }

  .title {
    font-size: 45px;
  }
}

.Itinerary-div {
  margin-top: 10vh;
}

.CCourse-Section2 {
  margin-top: 10vh;
}




.CCourse-Image {
  height: 65vh;
  width: 50vh;
  position: absolute;
  top: 25px;
  right: 90px;
}

.CCourse-Image img {
  /* position: absolute; */
  height: 100%;
  width: 100%;
}

.CCourse_i {
  border: 7px solid var(---TheamMColor);
  height: 65vh;
  width: 50vh;
}


/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.CCourse-Container {
  /* border: 1px solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CCourse-Images {
  height: 60vh;
  width: 50vh;
  box-shadow: 0 0 5px gray;
  border-radius: 5px;
}

.CCourse-Images img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.CCourse_Schedule-Title {
  position: relative;
  margin-bottom: 10vh;
}

.CCourse_Schedule-Title h4 {
  position: absolute;
  font-weight: bold;
  top: -51px;
  left: 81px;
  right: 0;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;
}

.CCourse_Schedule-Title h5 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.CCFeeDetails-title {}

.CCFeeDetails-title h3 {
  font-family: var(---titleFontS);
  font-weight: 600;
  color: black;
}

.CCourse-Second-Container {}

.CCourse-Schedule-Info {}

.CCourse-Schedule-heading h4 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.CCourse-Schedule-Info li {
  text-align: left;
  margin: 10px;
}


/* ///////////////////////// */

.accordion-button:not(.collapsed) {
  color: white;
  background-color: var(---TheamMColor);
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(128, 0, 0, 0.404);
}

@media(max-width:430px) {
  .CCTimeSchedule {
    margin: 20px;
  }

  .CCourse_Schedule-Title h4 {
    top: -50px;
    left: 0px;
  }

  .CCourse-Second-Container {
    margin-top: 10vh;
  }

  .CCourse-Schedule-heading h4 {
    font-size: 28px;
  }

  .CCourse-Schedule-Info li {
    font-size: 14px;
  }

  .CC-Title h1 {
    font-size: 24px;
  }

  .CCTimeSchedule-title h3 {
    font-size: 20px;
  }

  .CCTimeSchedule-title {
    margin: 0px 0px 15px 0px;
  }

  .CCTimeSchedule-info h5 {
    font-size: 13px;
  }

  .CCFeeDetails-title {
    margin: 0px 0px 15px 0px;
  }

  .CCFeeDetails-title h3 {
    font-size: 20px;
  }

  .CCFeeDetails-info h5 {
    font-size: 14px;
  }


}

@media(max-width:770px) {
  .CCourse-Image {
    position: relative;
    top: 0;
    right: 0;
    border: 7px solid maroon
  }

  .CCourse_i {
    display: none;
  }

  .CCTimeSchedule {
    margin: 20px;
  }

  .CCFeeDetails {
    margin: 20px;
  }

  .CC-Title h1 {
    font-size: 25px;
  }
}


/* //////////////{/////////////////////////////////// Show Image /////////////////////////////////////////////// */

.SHowImage {
  height: 30vh;
}

.SHowImage img {
  height: 100%;
  width: 100%;
}