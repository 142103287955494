.TA-Section01 {
    padding-bottom: 15vh;
    background-color: #f3f0ed;
}

.TA-Section01-title h1 {
    color: black;
    font-size: 30px;
    font-family: var(---titleFont);
    font-weight: bold;
}

.TA-Section01-title p {
    font-family: var(---paraFont);
}


.TimeSchedule-image {
    /* border: 1px solid red; */
    height: 50vh;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.TimeSchedule-image img {
    height: 100%;
    width: 100%;

}


.FeeDetails-Image {
    /* border: 1px solid red; */
    height: 50vh;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.FeeDetails-Image img {
    height: 100%;
    width: 100%;
}

.TimeSchedule-info h5 {
    text-align: left;
}



.TATimeSchedule {
    padding: 30px;
    margin: 15px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 25px;
    background: #f3f3f3;
    height: 100%;
}

.TATimeSchedule-image {
    /* border: 1px solid red; */
    /* height: 50vh; */
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.TATimeSchedule-image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.TAFeeDetails {
    padding: 30px;
    height: 100%;
    margin: 15px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 25px;
    background: #f3f3f3;
}


.TAFeeDetails-Image {
    /* height: 50vh; */
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.TAFeeDetails-Image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.TATimeSchedule-info h5 {
    text-align: left;
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: left;

}

.TATimeSchedule-title h3 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.TAFeeDetails-title h3 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.TAFeeDetails-info h5 {
    text-align: left;
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: left;
}




/* ///////////////////////// section 2 //////////////////////////// */

.TA-Section02 {
    margin-bottom: 10vh;
}

.TA-Section02-title {
    padding-top: 5px;
    margin-bottom: 5vh;
    margin: 10px;
}

.TA-Section02-title h3 {
    color: black;
    font-size: 30px;
    font-family: var(---titleFont);
    font-weight: bold;
}

.TA-Section02-info {}

.TA-Section02-Image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.TheaterActingImage {
    height: 45vh;
    width: 80vh;
    margin: 10px;
}

.TA-Section02-info-description p {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 2px;
    font-family: var(---paraFont);
}

.TA-Section02-info-title h4 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.DP-Time {
    display: flex;
}


@media(max-width:430px) {
    .TATimeSchedule {
        margin: 0px;
    }

    .TAFeeDetails {
        margin: 20px 0px 0px 0px;
    }

    .TA-Section01-title h1 {
        padding: 20px 0px 0px 0px;
        font-size: 25px;
    }

    .TATimeSchedule {
        padding: 15px;
    }

    .TATimeSchedule-title {
        margin: 0px 0px 15px 0px;
    }

    .TATimeSchedule-info h5 {
        font-size: 14px;
    }

    .TAFeeDetails-info h5 {
        font-size: 14px;
    }

    .TA-Section02-title h3 {
        font-size: 25px;
    }

    .TA-Section02-info-title h4 {
        font-size: 15px;
    }

    .TA-Section02-info-description p {
        font-size: 13px;
    }

}