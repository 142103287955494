.RajendraTiwari-Section {
    /* border: 1px solid black; */
}

.RajendraTiwari-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../Images/SlidePics/Slide\ Pics7.JPG");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

}

.RajendraTiwari-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.RajendraTiwari-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

/* ///////////////// Section 2 ///////////////// */

.rajendra-tiwari-Section-2 {
    /* border: 1px solid red; */
    padding: 5vh 0px;
}

.rajendra-tiwari-Section2-con {
    /* border: 1px solid blue; */
}

.rajendra-tiwari-Section2-Image-con {
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}

.rajendra-tiwari-Section2-Image {
    /* border: 1px solid hotpink; */
    height: 50vh;
    width: auto;
    position: relative;
}

.rajendra-tiwari-Section2-Image img {
    height: 100%;
    width: 100%;
}

.rajendra-tiwari-Section2-Image:before {
    border: 10px solid var(---TheamMColor);
    content: "";
    height: 100%;
    left: -50px;
    position: absolute;
    text-align: center;
    top: -50px;
    width: 100%;
    z-index: -1;
}

.rajendra-tiwari-Section2-details-con {
    text-align: left;
}

.rajendra-tiwari-Section2-details-head {}

.rajendra-tiwari-Section2-details-head h3 {
    padding-bottom: 7px;
    display: inline-block;
    position: relative;
    font-family: var(---headerFont);
    font-size: 20px;
    color: var(---TheamMColor);
}

.rajendra-tiwari-text-style::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50%;
    background-color: #febf38;
}

.rajendra-tiwari-Section2-details-para {
    /* border: 1px solid blue; */
    margin: 0px 0px;
}

.rajendra-tiwari-Section2-details-para P {
    text-align: justify;
}

/* ////////////////////// Section 3 //////////////////////////// */

.rajendra-tiwari-Section-3 {
    /* border: 1px solid blue; */
}

.rajendra-tiwari-Section-3-con {
    border-bottom: 1px solid #80808042;
    padding: 5vh 0px;
    text-align: left;
}

.rajendra-tiwari-Section-3-points-con {
    margin-top: 20px;
}

.rajendra-tiwari-Section-3-points {
    /* border: 1px solid green; */
    margin-bottom: 20px;
}

.rajendra-tiwari-Section-3-points h3 {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
}

.rajendra-tiwari-Section-3-points h3 span {
    color: var(---TheamGoldColor);
    font-weight: 600;
}


/* //////////////// section 4 //////////////////////// */

.rajendra-tiwari-Section-4-images-con {
    /* border: 1px solid blue;   */
    padding: 5vh 0px;
}

.rajendra-tiwari-Section-4-images-heading {}

.rajendra-tiwari-Section-4-images-heading h2 {
    font-family: var(---headerFont);
    text-transform: capitalize;
    font-size: 30px;
    position: relative;
    display: inline-block;
}

.rajendra-tiwari-Section-4-images-div {
    padding: 30px;
}

.rajendra-tiwari-Section-4-images {
    height: 45vh;
    width: 100%;
    margin: 0px 0px 50px 0px;
}

.rajendra-tiwari-Section-4-images img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.swiper-pagination-bullet-active {
    background-color: #febf38;
}


/* section 4  */

.rajendra-tiwari-Section-4 {
    /* border: 1px solid blue; */
    padding: 3vh 0px;
}

.rajendra-tiwari-Section-4-con {
    /* border: 1px solid red; */
}

.rajendra-tiwari-Section-4-head-con {
    text-align: left;
}

.rajendra-tiwari-Section-4-head-con h2 {
    padding-bottom: 7px;
    display: inline-block;
    font-family: var(---headerFont);
    font-size: 30px;
    color: var(---headerColor);
    border-bottom: 1px solid #8080802b;
}

.rajendra-tiwari-Section-4-details {}

.rajendra-tiwari-Section-4-details-head {
    text-align: left;
    margin-bottom: 22px;
}

.rajendra-tiwari-Section-4-details-head h2 {
    font-family: var(---headerFont);
    font-size: 20px;
}

.rajendra-tiwari-Section-4-details-head h3 {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
}

.rajendra-tiwari-Section-4-last-para {
    text-align: left;
}

.rajendra-tiwari-Section-4-last-para p {
    font-family: var(---headerColor);
    margin: 0px;
}

@media(max-width:768px) {
    .RajendraTiwari-banner-Image {
        height: 50vh;
    }
}

@media(max-width:576px) {
    .rajendra-tiwari-Section-4-head-con h2 {
        font-size: 20px;
    }

    .rajendra-tiwari-Section-4-details-head h2 {
        font-size: 16px;
    }

    .RajendraTiwari-banner-Image {
        height: 30vh;
    }
}