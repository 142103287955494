/* ///////////////////////// section 1 //////////////////////////// */

.Video-Section {
    /* border: 1px solid black; */
}

.Video-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../Images/Group.webp");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.Video-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.Video-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

/* /////////////////////////// Section 2 /////////////////////////////////////////// */

.Video-Gallery {
    margin-top: 5vh;
}

.Video-title{
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;
    
  }
  .Video-title h4{
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
  }
  .Video-title h3{
    position: absolute;
    font-weight: bold;
    top: -51px;
    left: 81px;
    right: 0;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1; 
  }

.Video-title h4 {
    padding: 5px 15px 0px 15px;
}

.G-Video {
    margin: 10px;
    height: 35vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.G-Video img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

@media(max-width:425px){
    .Video-title h3{
        font-size: 55px;
        top: -27px;
        left: 0px;
    }
}

@media(max-width:768px){
    .Video-banner-Image {
        height: 50vh;
    }
    .G-Video {
        height: 50vh;
    }
}