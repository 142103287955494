.AboutUs-Section {
    /* border: 1px solid black; */
}

.AboutUs-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../Images/home\ gallery/Slide Pics45.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.AboutUs-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.AboutUs-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}


/* /////////////////////////// Section 2 /////////////////////////////////////////// */

.AboutUs-Image {
    height: 80vh;
    margin: 0px;
    width: 50%;
    clip-path: polygon(0 0, 85% 85px, 100% 90%, 0% 100%);
    background-color: maroon;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.AboutUs-Info {
    height: 80vh;
    margin: 0px;
    width: 100%;
    clip-path: polygon(103px 40px, 100% 0%, 100% 100%, 0% 80%);
    background-color: maroon;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.AboutUs_Image-Container {
    height: 50vh;
    width: 60vh;
    box-shadow: 0px 2px 10px var(---TheamGoldColor);
    border-radius: 5px;
    margin-top: 30px;
}


.AboutUs_Image-Container img {
    height: 100%;
    width: 100%;
    border-radius: 5px;

}

.AboutUs_Details {
    width: 80vh;
    margin-left: 90px;
    box-shadow: 0px 2px 10px var(---TheamGoldColor);
    border-radius: 5px;
    padding: 10px;
    background: white;
}

.AboutUs_Details h3 {
    text-align: left;
    font-family: var(---titleFontS);
    font-weight: 600;
}

.AboutUs_Details p {
    text-align: left;
    font-family: var(---paraFont);
    font-size: 15px;
}

/* {///////////////////////////////////////////////// added section//////////////////////////////////////////////////////} */


.Aboutnew {
    /* -top: 10vh; */
    background-color: #f3f0ed;
}

.Aboutnew p {
    font-size: 15px;
    color: maroon;
    text-align: left;
}

.AboutNewSection {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.AboutUsNewSctionInfo {
    /* border: 1px solid blue; */
    height: 85%;
    /* width: 70vh; */
    margin: 10px 0px 30px 1px;
}

.AboutUs-New-Heading {
    text-align: left;
}

.AboutUs-New-Heading h3 {
    font-size: 40px;
    margin: 0;
    display: inline-block;
    width: 100%;
    line-height: 50px;
    font-weight: 400;
    color: var(---TheamGoldColor);
    font-family: Lora, serif;
}

.AboutUs-New-Heading h6 {
    font-size: 14px;
    font-family: Open Sans;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 10px;
    letter-spacing: .7px;
    color: var(---TheamMColor)
}


.AboutUs-New-Heading p {
    font-size: 15px;
    color: maroon;
}

.AU-Info-3 {}

.info-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.info-logo-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 10vh;
    width: 25vh;
    background-color: var(---TheamMColor);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    border-radius: 5px;
}

.info-logo-1 svg {
    font-size: 25px;
    color: var(---TheamGoldColor);
}



.info-text-1 {}


.AU-Info-3::after {
    /* border: 1px solid maroon; */
    content: "";
    height: 90%;
    left: 34px;
    position: absolute;
    top: 70px;
    z-index: -1;
}


.info-text-1 h4 {
    margin: 24px;
    color: var(---TheamGoldColor);
    font-family: Open Sans;
    font-size: 18px;
    text-align: left;
}

.info-text-1 p {
    font-size: 14px;
    text-align: left;
    margin: 10px 0px 0px 25px;

}

/* ////////////////////////////////// Section 3 /////////////////////////////////////////// */


.About-Founder-Title h3 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.Founder-Info p {
    font-family: var(---paraFont);
    font-size: 15px;
}

.Founder-Images {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.FName {
    /* border: 1px solid rgb(0, 85, 255); */
    background-color: #e9e9e9;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.FName h6 {
    font-size: 25px;
    padding: 10px;
}

.FImage {
    /* border: 1px solid red; */
    /* height: 40vh; */
    width: 40vh;
    margin: 20px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    padding: 5px;
}

.FImage img {
    height: 100%;
    width: 100%;
}

.Founder-Description p {
    font-family: var(---paraFont);
    font-size: 15px;
}





.welcome-Image-con {
    position: relative;
    height: 100%;
    padding-top: 50px;
}

.AboutUs-AB-FImage {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 10px;
    height: 55vh;
    /* width: 40vh; */
    margin: 70px 10px 10px 10px;
    border: 5px solid white;
}

.AboutUs-AB-FImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.AboutUs-AB-SImage {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    height: 55vh;
    /* width: 40vh; */
    margin: 10px;
    border: 5px solid white;
}

.AboutUs-AB-SImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.AboutUs-AB-TImage {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    height: 45vh;
    /* width: 50vh; */
    margin: 10px;
    position: relative;
    top: -150px;
    left: 110px;
    border: 5px solid white;
}

.AboutUs-AB-TImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.welcome-section {
    /* border: 1px solid red; */
    margin-top: 10vh;
}

.welcome-Image {
    padding: 20px;
    height: 70vh;
    /* border: 1px solid black; */
}

.welcome-Image img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

/* ///////////////////////////// About section 3 //////////////////////////////////// */

.About-Section-3 {
    /* border: 1px solid blue; */
    padding: 20px;
}

.About-Section-3-Points-con {
    /* border: 1px solid red; */
}

.About-Section-3-head-points {}

.About-Section-3-head-con {
    text-align: left;
}

.About-Section-3-head-con h3 {
    /* border: 1px solid green; */
    display: inline-block;
    margin: 0px;
    font-size: 20px;
    padding-bottom: 5px;
    position: relative;
    font-family: var(---headerFont);
}

.textstyle::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 50px;
    height: 2px;
    background-color: #febf38;
    border-radius: 10px;
}

@media(max-width:2560px) {
    .AboutUs-AB-TImage {
        height: 35vh;
        width: 40vh;
    }
}

@media(max-width:1150px) {
    .AboutUs-AB-TImage {
        height: 40vh;
        width: 50vh;
    }

    .AboutUs_Details {
        margin-left: 173px;
    }

    .AboutUs_Details p {
        font-size: 14px;
    }
}

@media(max-width:800px) {
    .AboutUs-AB-FImage {
        width: 100%;
        height: 35vh;
    }

    .AboutUs-AB-SImage {
        width: 100%;
        height: 35vh;
        margin: 0px;
    }

    .AboutUs-AB-TImage {
        width: 30vh;
        height: 35vh;
        position: initial;
        margin-left: 100px;
    }

    .welcome-heading h4 {
        display: none;
    }

    .BackImage-Text h1 {
        font-size: 70px !important;
    }

    .AboutUs_Image-Container {
        height: 44vh;
        width: 45vh;
    }

    .AboutUs_Details {
        width: 61vh;
        margin-left: 65vh;
    }

    .AboutUs_Details p {
        font-size: 12px;
    }
}

@media(max-width:768px) {
    .AboutUs-banner-Image {
        height: 40vh;
        background-position: center;
    }

    .Alumini-S2-Description h3 {
        font-size: 25px;
    }

}

@media(max-width:576px) {
    .AboutUs-AB-FImage {
        width: 50%;
        height: 35vh;
        margin-left: 130px;
    }

    .AboutUs-AB-SImage {
        width: 50%;
        height: 35vh;
        margin: 0px;
    }

    .AboutUs-AB-TImage {
        width: 40vh;
        height: 40vh;
        position: initial;
        margin-left: 100px;
    }

    .AboutUs-Image {
        position: relative;
        width: 100%;
    }

    .AboutUs_Details {
        padding: 5px;
        margin-left: 90px;
    }

    .AboutUs-AB-FImage {
        height: auto;
        width: 100%;
        margin: 0px 0px 30px 0px;
    }

    .AboutUs-AB-SImage {
        width: 100%;
        height: auto;
    }

    .About-Section-3 {
        padding: 0px;
    }
}

@media(max-width:380px) {
    .AboutUs-Image {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    .AboutUs-Info {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    .AboutUs_Details {
        width: 100%;
        margin: 10px;
    }

    .AboutUs-banner-Image {
        height: 30vh !important;
    }

}

@media(max-width:350px) {
    .AboutUs-AB-TImage {
        width: 30vh;
        height: 35vh;
    }

}