.contact-page-section-2 {}

.contact-page-section-main-con {
    padding: 10vh 0px;
}

.contact-page-section-contact-card-body {}

.contact-page-section-contact-card {
    border: 1px solid rgb(199, 199, 199);
    padding: 20px;
    border-radius: 10px;
    min-height: 40vh;
}

.contact-page-section-contact-detils-con {
    display: flex;
    align-items: start;
    justify-content: start;
    /* border: 1px solid blue; */
    margin: 0px 0px 10px 0px;
}

.contact-page-section-contact-detils-con svg {
    max-width: fit-content;
    width: 20px;
    font-size: 20px;
    min-width: 20px;
}

.contact-page-section-details-text {
    text-align: left;
}

.contact-page-section-details-text a {
    margin: 0px 0px 0px 20px;
    display: block;
    text-decoration: none;
    color: black;
}

.contact-page-section-head-con {
    text-align: left;
}

.contact-page-section-head-con h3 {
    font-family: var(---paraFont);
    font-size: 25px;
    padding: 0px 0px 20px 0px;
    margin: 0px;
}

.contact-page-section-contact-form-and-map {
    /* border: 1px solid blue; */
}

.contact-page-section-details-map-con {
    /* border: 1px solid red; */
}

.contact-page-section-map-01 {
    /* border: 1px solid greenyellow; */
    height: 40vh;
    margin-bottom: 30px;
}

@media(max-width:768px){
    .contact-page-section-contact-card-body {
        margin-top: 30px;
    }
}