.NishthaTiwari-Section {
    /* border: 1px solid black; */
}

.NishthaTiwari-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../Images/SlidePics/Slide\ Pics9.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

}

.NishthaTiwari-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.NishthaTiwari-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

@media(max-width:768px) {
    .NishthaTiwari-banner-Image {
        height: 50vh;
    }
}

@media(max-width:576px){
    .NishthaTiwari-banner-Image {
        height: 30vh;
    }
}