.admission-Section {
    /* border: 1px solid black; */
}

.admission-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../Images/SlidePics/Slide\ Pics41.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

}

.admission-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.admission-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}


/* /////////////////////////////// Section 2 //////////////////////////////////// */


.admission-Section-2 {
    padding: 5vh 0px;
}

.admission-Section-2-form-con-personal-details {
    text-align: left;
}

.admission-Section-2-form-con-personal-details h2 {
    padding: 25px 0px;
    font-family: var(---headerFont);
    text-transform: capitalize;
}


/* ///////////////////////////////////////// Section 3 ////////////////////////////////////// */

.admission-section-3-con {
    /* border: 1px solid blue; */
}

.admission-section-3-con-details {
    text-align: left;
    margin: 30px 0px;
}

.admission-section-3-con-details h2 {
    position: relative;
    display: inline-block;
    font-size: 25px;
    font-family: var(---headerFont);
    color: var(---TheamMColor);
    padding-bottom: 10px;
}

.admission-head-style::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 2px;
    width: 30%;
    background-color: var(---TheamGoldColor);
    border-radius: 10px;
}

.admission-section-3-con-details p {
    font-size: 15px;
}

.Payment-section-details-con {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 15px;
}

.Payment-section-details-con h3 {
    font-family: var(---headerFont);
    margin: 0px 0px 0px 0px;
    font-size: 25px;

}

.Payment-section-details-con p {
    font-family: var(---headerFont);
    margin: 0px 0px 0px 15px;
    font-size: 18px;
}

@media(max-width:768px) {
    .admission-banner-Image {
        height: 50vh;
    }

    .Payment-section-details-con h3 {
        font-size: 20px;
    }

    .Payment-section-details-con p {
        font-size: 16px;
    }
}


@media(max-width:576px){
    .admission-banner-Image {
        height: 30vh;
    }
}