.acting-courses-section-1-main-con {
    /* border: 1px solid blue; */
    height: 82vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.actingcourses-section-1-back-image {
    background: url("../../Images/home\ gallery/Slide Pics39.jpg") !important;
    background-size: cover !important;
    background-position: center;

}


.acting-courses-section-2-swiper {
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;

}

.acting-courses-section-banner-con {
    /* border: 1px solid red; */
    height: 90%;
    width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.acting-courses-banner-image01 {
    background-image: url("../../Images/D_in_AImage02.jpg");
    background-size: cover;
    background-attachment: fixed;
}

.action-courses-section-text {
    /* border: 1px solid blue; */
    width: 40%;
    padding: 50px 20px;
    width: 40%;
    padding: 50px 20px;
    margin: 0px 0px 0px 50px;
    background: linear-gradient(112.1deg, rgb(32 38 57 / 16%) 11.4%, rgb(63 76 119 / 21%) 70.2%);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    border-radius: 6px;
}

.action-courses-section-text h1 {
    font-family: var(---headerFont);
    color: white;
    font-size: 35px;
}

.action-courses-section-text h2 {
    font-family: var(---headerFont);
    color: white;
    font-size: 30px;
}


/* section 2  */

.acting-courses-section-2 {
    background-color: black;
    padding: 10vh 0px;
}

.acting-courses-section-2-main-con {
    /* border: 1px solid red; */
}

.acting-courses-section-2-details-main-con {}

.acting-courses-section-2-details {}

.acting-courses-section-2-details-images {
    /* border: 1px solid blue; */
    height: 50vh;
    width: auto;
    background-color: black;
}

.acting-courses-section-2-details-images img {
    height: 100%;
    width: 100%;
    background-color: black;
}

/* /////////////////// Form //////// */

.stock-details-section-2-right-side-main-con {
    /* border: 1px solid blue; */
}

.stock-details-2-right-side-form {
    border-radius: .625rem;
    border: 1px solid rgb(132, 132, 132);
}

.stock-details-2-right-side-form-title {
    /* border: 1px solid red; */
    text-align: left;
    border-bottom: 1px solid rgba(146, 146, 146, 0.175);
    padding: 15px;
    position: sticky;
    top: 0;
}

.stocks-section-main-con a {
    text-decoration: none;
    color: black;
}

.stock-details-2-right-side-form-title h3 {
    font-size: 30px;
    color: white;
    font-family: var(---headerFont);
}

.stock-details-2-right-side-form-title h4 {
    font-family: var(---font01);
    font-size: 17px;
    font-weight: 300;
}

.stock-details-2-right-side-form-price {
    padding: 15px;
}

.stock-details-2-form-price-and-quent label {
    color: white !important;

}

/* /////////////////// End Form //////////////////// */


.acting-courses-section-2-details-text-con {
    /* border: 1px solid blue; */
    margin: 30px 0px;
}

.acting-courses-section-2-details-text {
    /* border: 1px solid red; */
    display: flex;
    margin: 0px 0px 20px 0px;
}

.acting-courses-section-2-details-text-image {
    /* border: 1px solid blue; */
    height: 35px;
    min-width: 35px;
    display: flex;
}

.acting-courses-section-2-details-text p {
    color: white;
    text-align: left;
    margin: 0px 0px 0px 20px;
}

@media(max-width:768px) {
    .action-courses-section-text {
        width: 100%;
        margin: 0px;
    }

    .acting-courses-section-2-details-images {
        height: auto;
    }

    .acting-courses-section-1-main-con {
        height: 50vh;
    }
}

:where(.css-dev-only-do-not-override-11xg00t).ant-input-outlined.ant-input-disabled, :where(.css-dev-only-do-not-override-11xg00t).ant-input-outlined[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: whitesmoke;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: whitesmoke !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
}


@media(max-width:576px){
    .acting-courses-section-1-main-con {
        height: 30vh;
    }
}