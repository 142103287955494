/* ///////////////////////// section 1 //////////////////////////// */

.CFC-Section01 {
  overflow: hidden;
  background-color: #f3f0ed;
  padding-bottom: 10vh;
}

.CFC-Title {
  margin: 20px;
}

.CFC-Title h1 {
  color: black;
  font-size: 30px;
  font-family: var(---titleFont);
  font-weight: bold;
}

.CFCTimeSchedule {
  padding: 30px;
  padding: 30px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  border-radius: 25px;
  background: white;
}

.CFCFeeDetails {
  padding: 30px;
  height: 100%;
}

.CFCFeeDetails-info h5 {
  text-align: left;
}


.CFCTimeSchedule-title h3 {
  font-family: var(---titleFontS);
  font-weight: 600;
}

.FeeDetails-title h3 {
  font-family: var(---titleFontS);
  font-weight: 600;
}

.CFCFeeDetails {
  padding: 30px;
  height: 100%;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  border-radius: 25px;
  background: white;
}

.CFCTimeSchedule-info h5 {
  font-family: var(---paraFont);
  font-size: 15px;
  text-align: left;
}

.CFCFeeDetails-info h5 {
  font-family: var(---paraFont);
  font-size: 15px;
}

/* ///////////////////////// section 2 //////////////////////////// */

.CFC-Section02 {
  margin-top: 5vh;
}

.CFC-Section02-Detail {
  margin: 20px;
  height: 100%;
  padding: 10px;
}

.CFC-Section02-Detail:hover {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
  border-radius: 25px;
}

.CFC-Section02-title h3 {
  color: black;
  font-size: 30px;
  font-family: var(---titleFont);
  font-weight: bold;
}

.CFC-Section02-days {}

.CFC-Section02-days h4 {
  font-family: var(---titleFontS);
  font-weight: 600;
}

.CFC-Section02-days {}


.CFC-Section02-info {
  text-align: left;
}

.TimeSchedule-info h5 {
  text-align: left;
}

.CFC-Section02-info p {
  margin: 5px;
  font-family: var(---paraFont);
}


/* /////////////////////////////////////////////// */

.CFC-Container {
  /* border: 1px solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CFC-Images {
  height: 60vh;
  width: 50vh;
  border-radius: 5px;
  box-shadow: 0 0 5px gray;
}

.CFC-Images img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.CFC_Schedule-Title {
  position: relative;
  margin-bottom: 10vh;
  margin-top: 10vh;
}

.CFC_Schedule-Title h4 {
  position: absolute;
  font-weight: bold;
  top: -51px;
  left: 81px;
  right: 0;
  font-family: 'Qwitcher Grypen', cursive;
  font-size: 85px;
  color: lightgrey;
  z-index: -1;
}

.CFC_Schedule-Title h5 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.CFC-Second-Container {}

.CFC-Schedule-Info {}

.CFC-Schedule-heading h4 {
  font-family: 'PT Serif', serif;
  font-size: 35px;
  font-weight: bold;
}

.CFC-Schedule-Info li {
  text-align: left;
  margin: 10px;
}

.CFCFeeDetails-title h3 {
  font-family: var(---titleFontS);
  font-weight: 600;
}




@media(max-width:430px) {
  .CFCTimeSchedule {
    margin: 0px;
  }

  .CFCFeeDetails {
    margin: 20px 0px 0px 0px;
  }

  .CFC-Title h1 {
    font-size: 20px;
  }

  .CFC_Schedule-Title h4 {
    top: -50px;
    left: 0px;
  }

  .CFCTimeSchedule-info h5 {
    font-size: 14px;
  }

  .CFCTimeSchedule-title {
    margin: 0px 0px 17px 0px;
  }

  .CFCFeeDetails-title{
    margin: 0px 0px 17px 0px;
  }

  .CFCFeeDetails-info h5{
    font-size: 14px;
  }

  .CFC-Schedule-heading{
    margin: 20px 0px 0px 0px;
  }

  .CFC-Schedule-heading h4 {
    font-size: 30px;
  }

  .CFC-Schedule-Info li {
    font-size: 14px;
  }

}