.certificates-Section {
    /* border: 1px solid black; */
}

.certificates-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../Images/SlidePics/Slide\ Pics6.JPG");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top;

}

.certificates-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.certificates-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}


/* content  */

.certificates-section-2-content-main-con {}

.certificates-section-2content-con {
    text-align: left;
    margin-bottom: 30px;
}

.certificates-section-2content-con h2 {
    font-family: var(---headerFont);
    font-size: 30px;
}

.certificates-section-2content-con p {
    
}

/* ////////////////////////// section 2 //////////////////////////// */

.certificates-section-2-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.certificates-section-2-Images-main-con {
    /* border: 1px solid red; */
    padding: 5px;
}

.certificates-section-2-Images-main-con01 {
    /* border: 1px solid green; */
}

.certificates-section-2-Images01 {
    /* border: 1px solid blue; */
    height: 33vh;
    margin: 10px 0px;
}

.certificates-section-2-Images02 {
    /* border: 1px solid blue; */
    height: 55vh;
    margin: 10px 0px;
}

.certificates-section-2-Images03 {
    /* border: 1px solid red; */
    height: 91vh;
    margin: 10px 0px;
}

.certificates-section-2-Images01 img {
    /* border: 1px solid blue; */
    height: 100%;
    width: 100%;
}

.certificates-section-2-Images02 img {
    /* border: 1px solid blue; */
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.certificates-section-2-Images03 img {
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
}

@media(max-width:768px) {
    .certificates-section-2-Images01 {
        height: auto;
    }

    .certificates-banner-Image {
        height: 50vh;
    }
}

@media(max-width:768px) {
    .certificates-section-2-Images03 {
        height: 100%;
    }
}



@media(max-width:576px){
    .certificates-banner-Image {
        height: 30vh;
    }
}