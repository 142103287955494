/* ///////////////////////// section 1 //////////////////////////// */

.ContactUs-Section {
    /* border: 1px solid black; */
}

.ContactUs-banner-Image {
    height: 60vh;
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: center;
    background: url("../Images/contact\ banner.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.ContactUs-Banner-text h1 {
    color: var(---TheamGoldColor);
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    padding: 0px;
    color: white;
    margin-left: 50px;
}

.ContactUs-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

/* ///////////////////////// section 2 //////////////////////////// */

.ContactUs-Section-2 {
    margin-top: 10vh;
}


.contactUsMainImageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Contact-main-Div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ContactUs-Images {
    /* border: 1px solid red; */
    height: 80vh;
}

.ContactUs-Images img {
    height: 100%;
    width: 100%;
}

.Time {
    text-align: left;
}

.Time h4 {
    font-family: var(---headerFont);
    color: var(---headerColor);
    font-weight: 600;
    font-size: 30px;
}

.Time p {
    margin: 0px;
    padding: 0px;
    color: var(---para);
    font-weight: 500;
}

.map {
    margin-top: 5vh;
    height: 50vh;
}

.contact {
    text-align: left;
    margin-top: 25px;
}

.Address {
    display: flex;
}

.Address span {
    font-size: 30px;
    margin-right: 15px;

}

.Address span svg {
    color: gray;
}

.Address p {
    display: flex;
    color: var(---para);
    font-weight: 500;
    font-size: 15px;
}

.Address-Detail h6{
    font-family: var(---headerFont);
    color: var(---headerColor);
    margin: 5px;
    font-size: 18px;
}

.phoneno {
    display: flex;
    margin-top: 5px;
}

.phoneno span {
    font-size: 30px;
    margin-right: 15px;
}

.phoneno p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(---para);
    font-weight: 500;
}

.Email {
    display: flex;
    margin-top: 15px;
}

.Email span {
    font-size: 30px;
    margin-right: 15px;
}

.Email p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(---para);
    font-weight: 500;
}

/* CAREERS  */

.CAREERS {}

.CAREERS h4 {
    text-align: left;
    font-family: var(---headerFont);
    color: var(---headerColor);
    font-weight: 600;
    font-size: 30px;
}

.CAREERS p {
    text-align: left;
    color: var(---para);
    font-weight: 500;
}

.mailTitle h5 {
    font-family: var(---headerFont);
    color: var(---headerColor);
    font-weight: 600;
    font-size: 25px;
}

.PhonenoDetails {
    margin-top: 10px;
}

.PhonenoDetails h6 {
    font-family: var(---headerFont);
    color: var(---headerColor);
    margin: 5px;
    font-size: 18px;
}

.PhonenoDetails p {
    padding: 0px;
    margin: 0px;
}

.Email-Details {
    margin-top: 12px;
}

.Email-Details h6 {
    font-family: var(---headerFont);
    color: var(---headerColor);
    font-size: 18px;
}

.Email-Details p {
    margin: 0px;
    padding: 0px;
}

@media(max-width:768px) {
    .ContactUs-Images {
        width: 50vh;
    }

    .Time h4 {
        text-align: center;
    }

    .Time p {
        text-align: center;
    }

    .contact {
        width: 60vh;
    }

    .Time {
        margin-top: 15vh;
    }

    .CAREERS {
        margin-top: 15vh;
    }

    .CAREERS p {
        font-weight: 400;
    }

    .ContactUs-banner-Image {
        height: 50vh;
    }

    .ContactUs-Banner-text h1 {
        font-size: 35px;
    }
}


@media(max-width:576px){
    .ContactUs-banner-Image {
        height: 30vh;
    }
}