@import url('https://fonts.googleapis.com/css2?family=Andika&family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

/* //////////////////////////////////////////////////////// Section1 ///////////////////////////////////////////////////////////////////////// */
.Home-banner {
    /* background: url("../Images/Home/Banner/ABSS\ WEBSITE\ BANNER.png"); */
    height: 100vh;
    width: 100%;
}

.Home-banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.Home-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.Home-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: #fff;
    margin: 0px;
    padding: 0px;

}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.HB-Text p {
    /* color: white; */
    font-size: 30px;
    font-family: 'Courgette', cursive;
    color: var(---TheamGoldColor);
}


.Home-new-section-banner-image-con {
    /* border: 1px solid blue; */
}

/* //////////////////////////////////////////// SECTION 2 /////////////////////////////////////////// */

.Home-Section-2 {
    margin-top: 20vh;
}

.top-logo {
    height: 50px;
    width: 50px;
}

.ClassesFor h3 {
    font-family: var(---titleFontS);
    font-weight: 600;
    font-size: 35px;
    color: #febf38;
    margin-bottom: 20px;
    text-transform: capitalize;
}

/* ======================================== Category Section ======================== */


.react-player {
    border-radius: 8px;
}

.destinationSection {
    background-color: #eee;
}

.destinationSection .titleSection img {
    height: 66px;
}

.titleSection h2 {
    /* font-family: 'Crimson Text', serif; */
    font-family: amithenregular;
    font-size: 35px;
    font-weight: bold;
}

.titleSection p {
    font-weight: 600;
    font-family: 'Nanum Myeongjo', serif;
    color: #000000c4;
}

.titleSection img {
    height: 60px;
    width: 60px;
}

.categryBanner {
    position: relative;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: start;   */
    align-items: flex-end;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mid-image {
    width: 95%;
    height: 95%;
}

.categryBanner h3 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    font-family: var(---titleFontS);
}

.categryBanner h3::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2px;
    background: #fff;
    right: 0;
    bottom: 0;
}

.ClassDetail {
    position: relative;
}

.ClassInfo p {
    color: white;
    /* font-family: 'Amiri', serif; */
    font-size: 14px;
}

.ClassInfo h5 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    font-family: var(---titleFontS);
}

.ClassInfo {
    border-radius: 20px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgb(0, 0, 0, .7), rgb(0, 0, 0, .7));
    padding: 10px;


    transform: translateY(100px);
    opacity: 0;
    transition: 0.5s;
}

.ClassDetail:hover .categryBanner h3 {
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
}

.ClassDetail:hover .ClassInfo {
    opacity: 1;
    transform: translateY(0px);
}





/* //////////////////////////////////////////////////////// Section2 ///////////////////////////////////////////////////////////////////////// */


.Home-Section2 {
    background-color: #f2efec;
    /* position: relative; */
    padding-top: 5vh;
}

.HSection-2-Informastion {
    width: 80vh;

}

.HSection-2-info {
    /* border: 1px solid red; */
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HSection-2-image {
    /* border: 1px solid black; */
    text-align: left;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.HSection-2-text h5 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    color: #777;
    font-weight: bold;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 20px;
    text-align: left;
}

.HSection-2-text h5::after {
    position: absolute;
    content: "";
    bottom: 0;
    width: 73px;
    height: 4px;
    left: 0;
    background-color: #571f9c;

}

.HSection-2-text h3 {
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: bold;
    background: linear-gradient(to bottom, #353094, #9a26b0);
    background: -webkit-linear-gradient(to bottom, #353094, #9a26b0);
    background: -ms-linear-gradient(to bottom, #353094, #9a26b0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
}


.Hsection-2-Description {
    display: flex;
    margin-top: 15px;
}

.Blockquote {
    /* border: 1px solid blue; */
}

.Blockquote svg {
    color: #a6bcd1;
    font-size: 6vh;
}



.Hsection-2-Desc {
    /* border: 1px solid red;    */
    width: 65vh;
}

.Hsection-2-Desc q {
    font-size: 17px;
    font-weight: 500;
    max-width: 100%;
    display: inline-block;
    color: #7777;
    text-align: left;
}

.HSection-2-Dimage {
    border: 8px solid rgb(255, 255, 255);
    height: 75vh;
    width: 60vh;
    position: absolute;
    bottom: -60px;
    left: 0;
    background-color: aliceblue;
    box-shadow: 0 0 5px #d5d5d5;
    /* border-radius: 5px; */
}

.HSection-2-Dimage img {
    height: 100%;
    width: 100%;
}

@media(max-width:1000px) {
    .HSection-2-Dimage {
        position: relative;
        bottom: auto;
        left: auto;
    }

    .HSection-2-Dimage {
        position: relative;
    }

}

/* ////////////////////////////////////////////////////// 4 CARDS /////////////////////////////////////////////////////// */

.Home-Section3 {
    margin-top: 15vh;
}


.course4 {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    box-shadow: 0 0 7px #808080;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.1);
}

.Course4-Image {
    height: 30vh;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.Course4Conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.Course4-Image img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.Course4-info h4 {
    margin: 5px;
    margin-left: 10px;
    font-family: var(---titleFont);
    font-weight: 600;
    text-align: left;
    font-size: 22px;
}

.Course4-info p {
    padding: 10px;
    text-align: left;
    font-size: 14px;
    font-family: var(---paraFont);
}

.course4 h5 {

    color: white;
}

.Coursetext {
    position: absolute;
    /* bottom: 0;
    left: 0;
    right: 0; */
    padding: 20px;
    transition: 0.8s all ease;
    color: #ccc;
    transform: translateY(200px);
    opacity: 0;
    height: 60vh;
}

.Coursetext p {
    text-align: left;
}

.course4:hover .Coursetext {
    transform: translateY(0px);
    opacity: 1;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6))
}

@media only screen and (max-width:900px) {
    .course4 {
        display: block;
    }
}



/* ////////////////////////////////////////////////////// Section 3 //////////////////////////////////////////////////////////// */

.Se3container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
    height: 100vh;
}

.container .card {
    vertical-align: top;
    width: 280px;
    height: 450px;
    position: relative;
    margin: 30px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.8);
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    text-align: center;
}

.card .content {
    padding: 20px;
    transition: 0.8s all ease;
    color: #ccc;
    transform: translateY(200px);
    opacity: 0;
}

.card:hover .content {
    transform: translateY(0px);
    opacity: 1;
}

.card img {
    position: absolute;
    filter: invert(82%) sepia(60%) saturate(2929%) hue-rotate(353deg) brightness(105%) contrast(103%);
    width: 140px;
    top: 50px;
    opacity: 0.2;
    z-index: -1;
}

/* h1 {
    color: #fff;
    text-align: center;
    z-index: 9;
    position: relative;
    margin-bottom: -20px;
    padding-bottom: 0;
    padding-top: 20px;
    font-weight: 400;
} */

.card .content h2 {
    color: #fff;
    font-weight: 400;
}

.card .content a {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    padding: 8px 30px 11px;
    border: 2px solid #fff;
    text-transform: uppercase;
    color: #fff;
    border-radius: 50px;
    box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.8);
    transition: 0.8s all ease;
}

.card .content a:hover {
    background: #9C27B0;
}

.course4_row {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width:900px) {
    .container {
        display: block;
    }
}


/* /////////////////////////////// section4 /////////////////////////// */


/* .welcome-Image-con {
    border: 1px solid green;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 80vh;
    position: relative;
} */

.welcome-Image-con-Home {
    position: relative;
    height: 100%;
    padding-top: 50px;
}

.Home-AB-FImage {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 10px;
    height: 55vh;
    width: 40vh;
    margin: 70px 10px 10px 10px;
    border: 5px solid white;
}

.Home-AB-FImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Home-AB-SImage {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    height: 55vh;
    width: 40vh;
    margin: 10px;
    border: 5px solid white;
}

.Home-AB-SImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.Home-AB-TImage {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    height: 45vh;
    width: 50vh;
    margin: 10px;
    position: relative;
    top: -150px;
    left: 110px;
    border: 5px solid white;
}

.Home-AB-TImage img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Home-welcome-section {
    margin-top: 10vh;
    background-size: cover;
    padding: 10vh;
    background: black;
}

.welcome-Image {
    padding: 20px;
    height: 70vh;
    /* border: 1px solid black; */
}

.welcome-Image img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.welcome-detail {
    margin-left: 65px;
    object-fit: contain;
}

.welcome-material {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.welcome-heading {
    position: relative;
}

.welcome-heading h2 {
    font-weight: bold;
    font-family: 'PT Serif', serif;
    color: var(---primeColor);
    color: white;
}

.welcome-heading h4 {
    position: absolute;
    top: -30px;
    left: -11px;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 63px;
    color: lightgrey;
    z-index: -1;

}

.welcome-discription {
    font-family: 'Amiri', serif;
    font-size: 17px;
    color: white;
}

.Welcome-more-button {
    /* border: 1px solid red; */
    margin-top: 30px;
}

.Welcome-more-button a {
    display: block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-family: sans-serif;
    text-decoration: none;
    color: var(---TheamGoldColor);
    border: 2px solid white;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    transition: all .35s;
    border-radius: 40px;
}

.Welcome-more-button a span {
    position: relative;
    z-index: 2;
    color: white;
}

.Welcome-more-button a:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(---TheamMColor);
    transition: all .35s;
    border-radius: 40px;
}

.Welcome-more-button a:hover {
    color: #fff;
    border: 2px solid var(---TheamGoldColor);
}

.Welcome-more-button a:hover:after {
    width: 100%;
}

@media(max-width:800px) {
    .Home-AB-FImage {
        width: 100%;
        height: 35vh;
    }

    .Home-AB-SImage {
        width: 100%;
        height: 35vh;
        margin: 0px;
    }

    .Home-AB-TImage {
        width: 30vh;
        height: 35vh;
        position: initial;
        margin-left: 100px;
    }

    .welcome-heading h4 {
        display: none;
    }

    .BackImage-Text h1 {
        font-size: 70px !important;
    }
}

@media(max-width:500px) {
    .Home-AB-FImage {
        width: 50%;
        height: 35vh;
        margin-left: 130px;
    }

    .Home-AB-SImage {
        width: 50%;
        height: 35vh;
        margin: 0px;
    }

    .Home-AB-TImage {
        width: 40vh;
        height: 40vh;
        position: initial;
        margin-left: 100px;
    }

}

@media(max-width:350px) {
    .Home-AB-TImage {
        width: 30vh;
        height: 35vh;
    }
}



/* /////////////////////////////// section 5  /////////////////////////// */
.Home-Section5 {
    margin-top: 10vh;
    /* background-color: black; */
}

.Home-Gallery h3 {
    font-weight: bold;
    font-family: 'PT Serif', serif;
    text-align: center;
    padding-left: 12px;
}

.ImageGallery {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: right;
    position: relative;
    margin-top: 5vh;
}

.ImageGalleryBar {
    width: 80%;
    margin-top: 5vh;
}

.ImageGallery-Text h6 {

    font-family: 'Bebas Neue', cursive;
    color: #1b1b1b;
    font-size: 20px;

}

.BackImage-Text {
    /* border: 1px solid blue; */
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.BackImage-Text h1 {
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 100px;
    color: lightgrey;
    z-index: -1;
}


.Gallery-Right-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Gallery-Right-Button a {
    text-decoration: none;
}

.Home-Gallery h5 {
    font-weight: bold;
    font-family: 'PT Serif', serif;
    text-align: right;
    padding-right: 12px;
    color: var(---TheamGoldColor);
}

.FImages {
    /* box-shadow: 0px 2px 10px rgb(0 0 0 / 80%); */
    border-radius: 5px;
    height: 40vh;
    margin-top: 20px;
    overflow: hidden;
}

.FImages img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    transition: transform .4s;
    object-fit: cover;
    object-position: center;
}

.FImages:hover img {
    transform: scale(1.3);
    transform-origin: 50% 50%;
}

.SImages {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    height: 35vh;
    margin: 10px;
    margin-top: 80px;
    overflow: hidden;
}

.SImages img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    transition: transform .4s;
}

.SImages:hover img {
    transform: scale(1.3);
    transform-origin: 50% 50%;
}

.GalleryInfo {
    border: 1px solid red;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    height: 35vh;
    margin: 10px;
}

.gallery-first-row {
    display: flex;
    align-items: center;
    justify-content: end;
}

.gallery-Second-row {
    display: flex;
    align-items: center;
    justify-content: start;
}

@media(max-width:570px) {
    .gallery-first-row {
        display: inline;
    }

    .gallery-Second-row {
        display: inline;
    }
}



/* ///////////////////////////////////////// .Our-team  /////////////////////////////////////////////// */

.Our-Team-Section {
    padding: 10vh 0px;
    margin-top: 30px;
    /* background: url("../Images/volume-bg-01.png") repeat 0 0; */
    background: var(---blackColor);
    background-position: center center;
    background-repeat: repeat-x;
    -webkit-animation: move 40s linear infinite;
    animation: move 40s linear infinite;
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
}


.Our-Team-heading-Container {
    position: relative;
}

.OurTeam-Heading {}

.OurTeam-Heading h5 {
    font-family: 'Bebas Neue', cursive;
    color: var(---whiteColor);
    font-size: 20px;
}

.OurTeam-Heading p {
    margin-top: 30px;
    font-weight: bold;
    font-family: 'PT Serif', serif;
    text-align: center;
    padding-left: 12px;
    color: var(---whiteColor);
}

.OurTeam-Back {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    z-index: -1;

}

.OurTeam-Back h1 {
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 100px;
    color: whitesmoke;
    z-index: 1;
}

.Our-Team-details {
    display: flex;
    margin-bottom: 15vh;
    justify-content: center;
}

.Our-Team-details:hover .Our-Team-image {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
}

.Our-Team-image {
    height: 55vh;
    width: 40vh;
    border-radius: 5px;
    position: relative;
}

.Our-Team-image img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.Our-Team-Info {
    writing-mode: vertical-lr;
    text-align: left;
}

.Our-Team-Info h6 {
    margin: 0px;
    padding: 0px;
    color: var(---whiteColor);
    font-family: var(---paraFont);
}

.Our-Team-Info h4 {
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    font-family: var(---titleFontS);
    color: var(---whiteColor);
}

.our-Team-Name {
    width: 100%;
    position: absolute;
    bottom: -20px;
    right: -75px;
    font-weight: bold;
    background: var(---blackColor);
}


.our-Team-Name h4 {
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 40px;
    color: var(---whiteColor);
    z-index: -1;
    margin: 0px;
}



/* /////////////////////////////////////// Facilities /////////////////////////////// */


.Testimonial-Section {
    margin-top: 20vh;
    background: url("../Images/volume-bg-01.png") repeat 0 0;
    background-position: center center;
    background-repeat: repeat-x;
    -webkit-animation: move 40s linear infinite;
    animation: move 40s linear infinite;
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
}

@keyframes MOVE-BG {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-187%);
    }
}


.Section-Facilities{
padding-bottom: 10vh;
}


.Facilities-Four {
    /* border: 1px solid red; */
    /* padding: 20px; */
    position: relative;
    height: 100%;
}

.Facilities-Four:hover .Facilities-logo img {
    transform: scale(1.1) rotate(360deg);
}

.Facilities-Four:hover {
    transition: transform 250ms;
    transform: translateY(-10px);
}

.Facilities-Container {
    border: 1px solid #febf38;
    margin: 2px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 5px;
    /* padding: 10px; */
    border-top: 5px solid #febf38;
    height: 100%;
}

.Facilities-logo {
    border-radius: 30px;
    display: inline-block;
    width: 50px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 38%;
    background: whitesmoke;
    border: 1px solid maroon;
}

.Facilities-logo img {
    border-radius: 30px;
    height: 100%;
    width: 100%;
    transition: all 0.9s;
}

.Facilities-main-heading {
    position: relative;
    margin-bottom: 10vh;
    margin-top: 0vh;
}

.Facilities-main-heading h5 {
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
}

.Facilities-main-heading h4 {
    position: absolute;
    font-weight: bold;
    top: -51px;
    left: 81px;
    right: 0;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;
}

.Facilities-Text h5 {
    margin: 40px 0px 5px 0px;
    font-size: 32px;
    font-family: 'Qwitcher Grypen', cursive;
    color: maroon;
}

.Facilities-Text p {
    font-family: 'Amiri', serif;
}


@media(max-width:440px) {
    .Facilities-logo {
        right: 40% !important;
    }
}



/* //////////////////////////////////// testimonial ///////////////////////////////////////////// */


.Testimonial-Section {
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Testimonials-Main-Heading {
    position: relative;
    margin-top: 10vh;
}

.Testimonials-Main-Heading h4 {
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
}

.Testimonials-Main-Heading h3 {
    position: absolute;
    font-weight: bold;
    top: -51px;
    left: 81px;
    right: 0;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;
}


.testimonial-Image-Container {
    /* border: 1px solid black; */
    height: 75vh;
}

.testimonial-Image-Container img {
    height: 100%;
    width: 100%;
}

.testimonial-Container {
    /* border: 1px solid blue; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* ///////////////////// */

.testimonial-detail {
    /* border: 1px solid red; */
    margin: 20px;
    /* margin-top: 30vh; */
    padding: 0px 55px;
}

.testimonial-text {
    /* border: 1px solid blue; */
}

.testimonial-text h4 {
    color: black;
}

.testimonial-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}

.testimonial-Profile-image {
    /* border-radius: 30px; */
    border: 2px solid black;
    height: auto;
    width: 10vh;
    margin: 20px;
}

.testimonial_Image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-Profile-image img {
    /* border-radius: 30px; */
    height: 100%;
    width: 100%;
    padding: 3px;
    /* background: black; */
}

.testimonial-text h4 {
    font-size: 15px;
    font-family: 'PT Serif', serif;
}

.Testimonial-Name h4 {
    font-size: 20px;
    font-family: 'PT Serif', serif;
    font-weight: 600;
}

.swiper-button-next:after {
    border: 1px solid black;
    padding: 10px 15px;
    font-size: 20px !important;
    border-radius: 30px;
    color: white !important;
    background-color: black;

}

.swiper-button-prev:after {
    border: 1px solid black;
    padding: 10px 15px;
    font-size: 20px !important;
    border-radius: 30px;
    color: white !important;
    background-color: black;

}

/* ////////////////////////////// */

.mid-Image {
    height: 20vh;
}

.mid-Image img {
    height: 100%;
    width: 100%;
}

/* /////////////////////////////// home-section blog images ////////////////////// */

.home-blog-section {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.home-blog-section-blog-head-con {}

.home-blog-section-blog-head-con h2 {
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
}

.home-section-blog-main-con {
    /* border: 1px solid blue; */
    margin-top: 40px;
}

.home-section-blog-main-image-con {
    /* border: 1px solid green; */

    height: 50vh;
}

.home-section-blog-main-image-con img {
    height: 100%;
    width: 100%;
}

/* ////////////////////////////////////// */
@media(max-width:768px) {
    .testimonial-Image-Container {
        width: 45vh;
    }

    .Facilities-Four {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .Home-banner {
        height: 70vh;
    }

    .Home-AB-FImage {
        width: 100%;
        height: auto;
        margin-left: 0px;
    }

    .Home-AB-SImage {
        width: 100%;
        height: auto;
        margin: 0px;
    }

    .FImages {
        height: 60vh;
    }

    .welcome-material {
        padding: 40px;
    }

    .Welcome-more-button a {
        display: block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        font-family: sans-serif;
        text-decoration: none;
        color: var(---TheamGoldColor);
        border: 2px solid whitesmoke;
        letter-spacing: 2px;
        text-align: center;
        position: relative;
        transition: all .35s;
        border-radius: 40px;
    }

    .home-section-blog-main-image-con {
        margin: 0px 0px 30px 0px;
    }

    .categryBanner {
        height: 70vh;
    }

    .welcome-detail {
        margin-left: 0px;
    }
}

@media(max-width:576px) {
    .welcome-material {
        padding: 40px 0px 0px 0px;
    }

    .Home-welcome-section {
        margin: 5vh 0px 0px 0px !important;
        padding: 0px;
    }

    .testimonial-detail {
        padding: 0px;
    }
    .Home-banner {
        height: fit-content;
    }
}

@media(max-width:430px) {
    .welcome-Image-con-Home {
        padding: 0px;
    }

    .Home-welcome-section {
        margin: 5vh 0px 0px 0px !important;
        padding: 0px;
    }

    .Home-Gallery h5 {
        font-size: 12px;
    }

    .welcome-material {
        margin-bottom: 50px;
    }

    .Testimonials-Main-Heading h3 {
        font-size: 60px;
        top: -34px;
        left: 6px;
    }
}

@media(max-width:450px) {
    .Facilities-main-heading h4 {
        top: -50px;
        left: 0px;
    }

    .BackImage-Text {
        top: -20px;
        left: -50px;
    }

    .BackImage-Text h1 {
        font-size: 55px !important;
    }

    .home-section-blog-main-image-con {
        height: auto;
    }
}