.Thater-Festival-Section-1-con {
    /* border: 1px solid red; */
    /* padding: 10vh 0px; */
}

.teater-festival-section-1-heading-con {
    /* border: 1px solid blue; */
}

.teater-festival-section-1-heading {
    /* border: 1px solid greenyellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vh 0px;
}

.teater-festival-section-1-heading h2 {
    display: inline-block;
    position: relative;
    font-size: 20px;
    font-family: var(---headerFont);
    margin: 0px;
    color: var(---TheamMColor);
}

.theater-festival-details-main-con {
    /* border: 1px solid blue; */
    /* padding: 5vh 0px; */
}

.teater-festival-first-con {
    /* border: 1px solid red; */
    height: 80vh;
    display: flex;
    align-items: center;
    background-image: url("../../Images/Theater\ Festival/TheaterFestival27.JPG");
    background-size: cover;
    background-attachment: fixed;
}

.teater-festival-first-details-con {
    /* border: 1px solid blue; */
    width: 40%;
    padding: 30px 30px;
    text-align: left;
    margin-left: 40px;
    background: linear-gradient(112.1deg, rgb(32 38 57 / 16%) 11.4%, rgb(63 76 119 / 21%) 70.2%);
    backdrop-filter: blur(6px);
    border-radius: 6px;
}

.teater-festival-first-details-con p {
    color: white;
}


/* //////////////// */

.teater-festival-second-con {
    /* border: 1px solid blue; */
    background-color: black;
}

.teater-festival-images-main-con {
    /* border: 1px solid red; */
}

.teater-festival-first-row-images-con {
    display: flex;
    justify-content: center;
    align-items: center;
}

.teater-festival-section-2-image-1 {
    /* border: 1px solid red; */
    height: 40vh;
    width: 60%;
    margin: 5px;
}

.teater-festival-section-2-image-1 img {
    height: 100%;
    width: 100%;
}

.teater-festival-section-2-image-2 {
    /* border: 1px solid blue; */
    width: 40%;
    height: 40vh;
    margin: 5px;
}

.teater-festival-section-2-image-2 img {
    height: 100%;
    width: 100%;
}

.teater-festival-section-2-details-con {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: left;
}

.teater-festival-section-2-details-info-main-con {
    margin: 0px 30px 0px 0px;
}

.teater-festival-section-2-details-info-main-con p {
    font-size: 15px;
    color: white;
}

/* //// */

.teater-festival-third-con {
    /* border: 1px solid red; */
    height: 80vh;
    display: flex;
    align-items: center;
    background-image: url("../../Images/Theater\ Festival/TheaterFestival43.JPG");
    background-size: cover;
    background-attachment: fixed;
}

.section-3-details {
    justify-content: end !important;
}


.teater-festival-third-details-con {
    /* border: 1px solid blue; */
    width: 40%;
    padding: 30px 30px;
    text-align: left;
    margin-right: 40px;
    background: linear-gradient(112.1deg, rgb(32 38 57 / 16%) 11.4%, rgb(63 76 119 / 21%) 70.2%);
    backdrop-filter: blur(6px);
    border-radius: 6px;
}

.teater-festival-third-details-con p {
    color: white;
}


.teater-festival-section-4-details-info-main-con {
    margin: 0px 0px 0px 30px;
    color: white;
}

.teater-festival-section-4-details-info-main-con p {}


@media(max-width:768px) {
    .teater-festival-first-details-con {
        margin-left: 0px;
        width: 100%;
    }

    .teater-festival-section-2-details-info-main-con {
        margin: 30px;
    }

    .teater-festival-third-details-con {
        width: 100%;
        margin-right: 0px;
    }

    .teater-festival-section-4-details-info-main-con {
        margin: 30px;
    }
}

@media(max-width:576px) {
    .teater-festival-first-row-images-con {
        display: block;
    }

    .teater-festival-section-2-image-1 {
        width: 100%;
        height: 100%;
    }

    .teater-festival-section-2-image-2 {
        width: 100%;
        height: auto;
    }
}