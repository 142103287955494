.Dp-Section01-title h1 {
    color: black;
    font-size: 30px;
    font-family: var(---titleFont);
    font-weight: bold;
}

.Dp-Section01-title {
    padding: 20px;
}

.Dp-Section01 {
    padding-bottom: 20vh;
    background-color: #f3f0ed;
}

.DATimeSchedule {
    padding: 30px;
    margin: 15px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 25px;
    background: #f3f3f3;
    height: 100%;
}

.DATimeSchedule-image {
    /* border: 1px solid red; */
    /* height: 50vh; */
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.DATimeSchedule-image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.DAFeeDetails {
    padding: 30px;
    height: 100%;
    margin: 15px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 80%);
    border-radius: 25px;
    background: #f3f3f3;
}


.DAFeeDetails-Image {
    /* height: 50vh; */
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.DAFeeDetails-Image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.DATimeSchedule-info h5 {
    text-align: left;
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: left;

}

.DATimeSchedule-title h3 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.DAFeeDetails-title h3 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.DAFeeDetails-info h5 {
    text-align: left;
    font-family: var(---paraFont);
    font-size: 15px;
    text-align: left;
}

/* ///////////////////////// section 2 //////////////////////////// */

.DP-Section02 {
    margin-top: 10vh !important;
    background-color: #dfdfdf;
}

.Dp-Section02-Detail {
    margin: 20px;
    padding: 10px;
    height: 100%;
}

.Dp-Section02-Detail:hover {
    border-radius: 5px;
    box-shadow: 0 0 5px #808080;
}

.DP-Section02-title h3 {
    color: black;
    font-size: 30px;
    font-family: var(---titleFont);
    font-weight: bold;
}

.Dp-Section02-days h4 {
    font-family: var(---titleFontS);
    font-weight: 600;
}

.Dp-Section02-info {
    text-align: left;
}


.Dp-Section02-info p {
    margin: 5px;
    font-family: var(---paraFont);
}

/* ///////////////////////////////////////////////////////////////////////////////// */

.DA-Container {
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.DA-Images {
    height: 60vh;
    width: 50vh;
    border-radius: 5px;
    box-shadow: 0 0 5px gray;
}

.DA-Images img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.DA_Schedule-Title {
    position: relative;
    margin-bottom: 10vh;
}

.DA_Schedule-Title h4 {
    position: absolute;
    font-weight: bold;
    top: -51px;
    left: 81px;
    right: 0;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;
}

.DA_Schedule-Title h5 {
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
}

.DA-Second-Container {}

.DA-Schedule-Info {}

.DA-Schedule-heading h4 {
    font-family: 'PT Serif', serif;
    font-size: 35px;
    font-weight: bold;
}

.DA-Schedule-Info li {
    text-align: left;
    margin: 10px;
}


@media(max-width:430px) {
    .DATimeSchedule {
        margin: 0px;
    }

    .DAFeeDetails {
        margin: 20px 0px 0px 0px;
    }

    .Dp-Section01-title h1 {
        font-size: 25px;
    }

    .DATimeSchedule-title h3 {
        font-size: 18px;
    }

    .DATimeSchedule-info h5 {
        font-size: 13px;
    }

    .DATimeSchedule-title {
        margin: 0px 0px 15px 0px;
    }

    .DA_Schedule-Title h4 {
        top: -50px;
        left: 15px;
    }

    .DA-Schedule-heading h4 {
        font-size: 30px;
    }

    .DAFeeDetails-title{
        margin: 0px 0px 15px 0px;
    }

    .DAFeeDetails-title h3 {
        font-size: 18px;
    }

    .DAFeeDetails-info h5 {
        font-size: 13px;
    }

    .DA-Schedule-heading {
        margin: 20px 0px 20px 0px;  
    }

    .DA-Schedule-Info li {
        font-size: 14px;
    }

}

@media(max-width:768px){
    .DATimeSchedule {
        margin: 0px 50px;
    }
    .DAFeeDetails {
        margin: 50px;
    }
}

@media(max-width:430px){
    .DA-Images {
        width: 100%;
    }
}

/* //////////////{/////////////////////////////////// Show Image /////////////////////////////////////////////// */

.SHowImage {
    height: 30vh;
}

.SHowImage img {
    height: 100%;
    width: 100%;
}