/* /////////////////////////////////////////// section 2 ////////////////////////////////////////////  */
.Main-Testimonial-Container{
    margin: 20px;
}
.Testimonial-Image-Con {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.Testimonial-Images {
    height: 40vh;
    width: 40vh;
    border-radius: 5px;
    box-shadow: 0 0 5px grey;
}

.Testimonial-Images img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Testimonial-Info-Con{
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 5px grey;
    padding: 10px;
}

.Testimonial-Name h4{
    text-align: left;
}

.Testimonial-Description p {
    text-align: left;
}