/* /////////////////////////////////////////// section 2//////////////////////////////////////////// */

.Faculty-Card {
    /* border: 1px solid red; */
    /* padding: 5px; */
    position: relative;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px grey;
}

.Faculty-Image {
    /* border: 1px solid black; */
    height: 50vh;
}

.Faculty-Image img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.Faculty-Name {
    /* border: 1px solid blue; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6));
    border-radius: 5px;
}

.Faculty-Name h5 {
    color: white;
}

.Faculty-Info {
    border: 1px solid green;

    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .6));
    padding: 10px;
    transform: translateY(100px);
    opacity: 0;
    transition: 0.5s;
}

.Faculty-Info h6 {
    text-align: left;
    color: white;
}

.Faculty-Card:hover .Faculty-Info {
    opacity: 1;
    transform: translateY(0px);
}


.Faculty-Card:hover {
    transform: translateY(-5px);
    transition: transform .25s;
}

.Faculty-social {
    border: 1px solid blue;
}



/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* h1{
    font-size:25px;
    text-align:center;
} */
.profile-card {
    margin-bottom: 10px;
}

.profile-card .profile-img {
    position: relative;
    overflow: hidden;
}

.profile-card .profile-img::after {
    content: "";
    border: 1px solid #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transform: scale(1, 1);
    transition: all 0.50s linear 0s;
}

.profile-card:hover .profile-img:after {
    transform: scale(0.9, 0.9);
    opacity: 1;
}

.profile-card .profile-img img {
    width: 100%;
    height: auto;
    transform: scale(1, 1);
    transition: all 0.50s linear 0s;
}

.profile-card:hover img {
    transform: scale(1.2, 1.2);
}

.profile-card .profile-img .over-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 10% 9%;
    text-align: left;
    background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6));
    transform: scale(0, 0);
    transition: all 0.50s ease 0.5s;
}

.profile-card:hover .over-layer {
    opacity: 1;
    transform: scale(0.9, 0.9);
}

.profile-card .profile-img .over-layer p {
    font-size: 14px;
    color: var(---TheamGoldColor);
    text-transform: capitalize;
}

.profile-card .profile-img .over-layer-one .title>span {
    display: block;
    margin: 5px 0;
}

.profile-card .profile-content {
    background: var(---TheamMColor);
    text-align: center;
    padding: 15px;
}

.profile-card .profile-content .title {
    font-size: 20px;
    color: #fff;
    margin: 0 0 10px;
    text-transform: uppercase;
}

.profile-card .profile-content .title>span {
    font-size: 16px;
    color: #FFFFff;
    margin: 0 7px;
    text-transform: capitalize;
}

.profile-card .profile-content .social-link {
    margin: 0;
    padding: 0;
}

.profile-card .profile-content .social-link li {
    display: inline-block;
    list-style: none;
    margin: 0 5px;
}

.profile-card .profile-content .social-link li a {
    display: inline-block;
    width: 30px;
    height: 30px;
    color: var(---TheamMColor);
    line-height: 30px;
    text-align: center;
    background: var(---TheamGoldColor);
}

.profile-card .profile-content .social-link li a:hover {
    text-decoration: none;
}

.social-link li a svg {
    font-size: 22 px;
}