.AshwiniShukla-Section {
    /* border: 1px solid black; */
}

.AshwiniShukla-banner-Image {
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../Images/SlidePics/Slide\ Pics36.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;

}

.AshwiniShukla-Banner-text h1 {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Anton', sans-serif;
    letter-spacing: 15px;
    color: white;
}

.AshwiniShukla-Banner-text h4 {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Tiro Telugu', serif;
    color: white;
}

.ashwinshukla-title {
    color: var(---TheamMColor);
    font-weight: 500 !important;
}

@media(max-width:768px) {
    .AshwiniShukla-banner-Image {
        height: 50vh;
    }
}


@media(max-width:576px){
    .AshwiniShukla-banner-Image {
        height: 30vh;
    }
}